<template>
  <div
    v-if="isShownBar"
    class="available-games-bar"
    :class="{ mobile: isMobile }"
  >
    <div class="left-panel">
      <div class="live-indicator" />
      <StLiveIndicator :color="gamesProportionColor" />
      <div class="text">
        {{ availableGamesText }}
      </div>
      <div class="games-count">
        <strong class="available-games">{{ availableGamesCount }}</strong>
        / {{ totalGamesCount }}
      </div>
    </div>
    <div class="right-panel">
      <NuxtI18nLink :to="createSupportLink('/articles/9516574')" class="link">
        {{ linkText }}
      </NuxtI18nLink>
      <StIcon
        name="cross-large"
        class="close"
        size="16"
        @click="handleCloseBar"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAvailableGamesStore } from '../../stores/useAvailableGamesStore'

const DAYS_TO_HIDE_BAR = 1

const { createSupportLink } = useSupportUrl()

const { t } = useI18n()
const { countryCode: detectedCountryCode } = useGeoAvailability()
const { getCountryByCode } = useCountries()
const { isMobile } = usePlatform()
const { parseDate } = useDate()

const availableGamesStore = useAvailableGamesStore()

const { availableGamesCount, totalGamesCount, status } =
  storeToRefs(availableGamesStore)

onNuxtReady(() => {
  // Если запрос еще никто не делал, мы его сделаем
  if (status.value === 'idle') availableGamesStore.fetchCounters()
})

const availableGamesMap = useLocalStorage<Record<string, number>>(
  'availableGamesMap',
  {},
)

const countryCode = computed(() => detectedCountryCode.value ?? 'unknown')
const countryName = computed(() => getCountryByCode(countryCode.value)?.name)

const linkText = computed(() =>
  isMobile.value ? t('casino.howToUnlock') : t('casino.howToUnlockAllGames'),
)

const availableGamesText = computed(() =>
  isMobile.value || !countryName.value
    ? t('casino.availableGames')
    : t('casino.availableGamesInCountry', {
        country: countryName.value,
      }),
)

function handleCloseBar() {
  availableGamesMap.value[countryCode.value] = parseDate().unix()
}

const gamesProportion = computed(
  () => +(availableGamesCount.value / totalGamesCount.value).toFixed(2),
)

const gamesProportionColor = computed<'green' | 'yellow' | 'red'>(() => {
  if (gamesProportion.value >= 0.8) return 'green'
  if (gamesProportion.value >= 0.5) return 'yellow'
  return 'red'
})

const now = ref(parseDate().unix())

const isShownBar = computed(() => {
  if (status.value !== 'success') return false
  if (gamesProportionColor.value === 'green') return false

  const lastCloseTime = availableGamesMap.value[countryCode.value]
  if (!lastCloseTime) return true

  const daysFromLastClose = parseDate(now.value).diff(lastCloseTime, 'days')
  return daysFromLastClose > DAYS_TO_HIDE_BAR
})
</script>

<style scoped>
.available-games-bar {
  position: sticky;
  z-index: 10;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-100) var(--spacing-150) var(--spacing-100)
    var(--spacing-125);

  background: var(--background-base, #111118);
  background-color: var(--background-base);
  border-top: var(--border-width-medium, 1px) solid
    var(--border-primary, rgb(255 255 255 / 12%));
  border-right: var(--border-width-medium, 1px) solid
    var(--border-primary, rgb(255 255 255 / 12%));
  border-left: var(--border-width-medium, 1px) solid
    var(--border-primary, rgb(255 255 255 / 12%));
  border-radius: var(--border-radius-100, 8px) var(--border-radius-100, 8px) 0 0;
  box-shadow:
    0 -8px 16px -8px rgb(17 17 24 / 64%),
    -8px 0 16px -4px rgb(17 17 24 / 64%),
    8px 0 16px -4px rgb(17 17 24 / 64%);

  .left-panel {
    display: inline-flex;
    gap: var(--spacing-025);
    align-items: center;

    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
  }

  .available-games {
    font: var(--desktop-text-sm-semibold);
    color: var(--text-primary);
  }

  .link {
    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
    text-decoration: none;

    &:hover {
      color: var(--text-primary);
    }
  }

  .right-panel {
    display: inline-flex;
    gap: var(--spacing-200);
    align-items: center;
  }

  .close {
    cursor: pointer;
    color: var(--text-secondary);

    &:hover {
      color: var(--text-primary);
    }
  }

  &.mobile {
    position: sticky;
    bottom: 64px;
    margin-bottom: calc(-1 * var(--spacing-100));
    padding: var(--spacing-075) var(--spacing-150) var(--spacing-075)
      var(--spacing-125);

    .left-panel {
      font: var(--mobile-caption-1-regular);
    }

    .available-games {
      font: var(--mobile-caption-1-regular);
    }
  }
}
</style>
