export const useAvailableGamesStore = defineStore('availableGames', () => {
  const status = ref<'idle' | 'pending' | 'error' | 'success'>('idle')
  const { isMobile } = usePlatform()
  const stFetch = useRawStFetch()

  const totalGamesCount = ref(0)
  const availableGamesCount = ref(0)

  async function fetchCounters() {
    status.value = 'pending'
    const allGamesRequest = stFetch('/casino/game/find', {
      method: 'post',
      body: {
        params: {
          forDesktop: isMobile.value ? undefined : true,
          forMobile: isMobile.value ? true : undefined,
        },
        pagination: {
          page: 0,
          perPage: 1,
          orderBy: [
            {
              fieldName: 'gameId',
              sortOrder: 'ASC',
            },
          ],
        },
      },
    })

    const availableGamesRequest = stFetch('/casino/game/find', {
      method: 'post',
      body: {
        params: {
          forDesktop: isMobile.value ? undefined : true,
          forMobile: isMobile.value ? true : undefined,
          allowedByCountryOnly: true,
        },
        pagination: {
          page: 0,
          perPage: 1,
          orderBy: [
            {
              fieldName: 'gameId',
              sortOrder: 'ASC',
            },
          ],
        },
      },
    })

    const [
      { data: allGames, error: allGamesError },
      { data: availableGames, error: availableGamesError },
    ] = await Promise.all([allGamesRequest, availableGamesRequest])

    const hasError = allGamesError || availableGamesError

    if (hasError) {
      status.value = 'error'
    } else {
      status.value = 'success'
      totalGamesCount.value = allGames.paging.total
      availableGamesCount.value = availableGames.paging.total
    }
  }

  return {
    totalGamesCount,
    availableGamesCount,
    fetchCounters,
    status,
  }
})
